<template>
	<body style="position: relative;">
		<div id="wrap">
			<section>
				<headerLayout></headerLayout>
			</section>
			<main>
				<section class="sub-visual">
					<div class="innWrap">
						<div class="txt">
							<h2>신청 현황</h2>
						</div>
						<div class="location">
							<ul>
								<li><a href="#">ORDER</a></li>
								<li><span>신청 현황</span></li>
							</ul>
						</div>
					</div>
				</section>
				<section class="sub-section aplcnPrstus">
					<div class="innWrap">
						<div class="lineBox">
							<div class="con01">
								<div class="multi">
									<div class="select w560px">
										<span class="placeholder">신규<span class="fcGy"></span></span>
										<div class="list nav">
											<ul>
												<li v-for="item in typeList" :key="item.code_cd" :value="item.code_cd" @click="selectValue('OD', item)">{{item.code_nm}}</li>
											</ul>
										</div>
									</div>
									<div class="select w560px" v-show="this.typeList_sel != '03'">
										<span class="placeholder" ref="custnmid"><span class="fcGy">고객명 / 고객ID</span></span>
										<div class="list">
											<ul>
												<li v-for="item in userInfo" :key="item.custId" :value="item.custId" @click="selectValue('CUST', item)">{{item.custNm}} / {{item.custId}}</li>
											</ul>
										</div>
									</div>
									<div class="select w560px" v-show="this.typeList_sel == '03'">
										<span class="placeholder" ref="scn"><span class="fcGy">서비스ID</span></span>
										<div class="list nav">
											<ul>
												<li v-for="item in userSvcInfo" :key="item.scn" :value="item.scn" @click="selectValue('SCN', item)">{{item.scn}}</li>
											</ul>
										</div>
									</div>
									<a class="btn-md01 btn-primary" @click="searchList">조회</a>
								</div>
							</div>
							<!-- 신청현황 테이블 시작-->
							<table class="tbl_type02 mgt50 con02" v-if="typeList_sel == '01'">
								<colgroup>
									<col style="width: 55px;">
									<col style="width: 85px;">
									<col style="width: 204px;">
									<col style="width: 83px;">
									<col style="width: 132px;">
									<col style="width: 265px;">
									<col style="width: 149px;">
									<col style="">
									<col style="width: 223px;">
								</colgroup>
								<thead>
									<tr>
										<th>선택</th>
										<th>구분</th>
										<th>신청서ID</th>
										<th>상태</th>
										<th>요청일시</th>
										<th>서비스</th>
										<th>회선번호</th>
										<th>설치주소</th>
										<th>건물명</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="typeList_sel == '01' && result == ''">
										<td colspan="9">조회된 데이터가 없습니다.</td>
									</tr>
									<tr v-if="typeList_sel == '01' && result" v-for="item in result" :key="item.aplshtid">
										<td class="agc">
											<div class="checkbox tbl-ckRadio">
												<input type="radio" name="aplsht" :id="item.aplshtid" :value="item.aplshtid" class="hide" @click="searchDetailInfo($event, item)">
												<label :for="item.aplshtid"></label>
											</div>
										</td>
										<td>신규</td>
										<td>{{item.aplshtid}}</td>
										<td :class="item.trtmstatcd == '3' ? 'fcRed' : (item.trtmstatcd == '2' ? 'fcBlue2' : '')">{{item.trtmstatnm}}</td>
										<td>{{item.rgstdt}}</td>
										<td>{{item.svctypenm}}</td>
										<td>{{item.connectlineno}}</td>
										<td>{{(item.addr != '' && item.addr != null) ? item.addr.split('^')[0] : ''}}</td>
										<td>{{(item.addr != '' && item.addr != null) ? item.addr.split('^')[1] : ''}}</td>
									</tr>
								</tbody>
							</table>
							<!-- 신청현황 테이블 끝-->
							<!-- 변경현황 테이블 시작-->
							<!-- |custind |codecd |codenm |aplshtid |billid |rgstdt |trtmstatcd |trtmstatnm |connectlineno |dongcd |addrbldgid |aplcntmobileno |svctypenm |svctypecd |addr | -->
							<table class="tbl_type02 mgt50 con02" v-if="typeList_sel == '02'">
								<colgroup>
									<col style="width: 55px;">
									<col style="width: 85px;">
									<col style="width: 204px;">
									<col style="width: 83px;">
									<col style="width: 132px;">
									<col style="width: 265px;">
									<col style="width: 149px;">
									<col style="">
									<col style="width: 223px;">
								</colgroup>
								<thead>
									<tr>
										<th>선택</th>
										<th>구분</th>
										<th>신청서ID</th>
										<th>상태</th>
										<th>요청일시</th>
										<th>서비스</th>
										<th>회선번호</th>
										<th>설치주소</th>
										<th>건물명</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="typeList_sel == '02' && result == ''">
										<td colspan="9">조회된 데이터가 없습니다.</td>
									</tr>
									<tr v-if="typeList_sel == '02' && result" v-for="item in result" :key="item.aplsht_id">
										<td class="agc">
											<div class="checkbox tbl-ckRadio">
												<input type="radio" name="aplsht" :id="item.aplshtid" :value="item.aplshtid" class="hide" @click="searchDetailInfo($event, item)">
												<label :for="item.aplshtid"></label>
											</div>
										</td>
										<td>변경</td>
										<td>{{item.aplshtid}}</td>
										<td :class="item.trtmstatcd == '3' ? 'fcRed' : (item.trtmstatcd == '2' ? 'fcBlue2' : '')">{{item.trtmstatnm}}</td>
										<td>{{item.rgstdt}}</td>
										<td>{{item.svctypenm}}</td>
										<td>{{item.connectlineno}}</td>
										<td>{{(item.addr != '' && item.addr != null) ? item.addr.split('^')[0] : ''}}</td>
										<td>{{(item.addr != '' && item.addr != null) ? item.addr.split('^')[1] : ''}}</td>
									</tr>
								</tbody>
							</table>
							<!-- 변경현황 테이블 끝-->
							<!-- AS신청현황 테이블 시작-->
							<table class="tbl_type02 mgt50 con02" v-show="this.typeList_sel == '03'">
								<colgroup>
									<col style="width: 55px;">
									<col style="width: 85px;">
									<col style="width: 204px;">
									<col style="width: 155px;">
									<col style="">
									<col style="width: 250px;">
									<col style="width: 200px;">
									<col style="width: 140px; ">
									<col style="width: 140px;">
								</colgroup>
								<thead>
									<tr>
										<th>선택</th>
										<th>구분</th>
										<th>AS접수번호</th>
										<th>상태</th>
										<th>서비스(상품)</th>
										<th>요청일시</th>
										<th>서비스번호</th>
										<th>상세정보</th>
										<th>취소여부</th>
									</tr>
								</thead>
								<tbody>
									<tr v-show="ttlogList == ''">
										<td colspan="9">조회된 데이터가 없습니다.</td>
									</tr>
									<tr v-show="ttlogList" v-for="item in ttlogList" :key="item.ttid">
										<td class="agc">
											<div class="checkbox tbl-ckRadio">
												<input type="checkbox" :id="item.ttid" class="hide" name="ckRadio" @click="selectValue('AS', item)"/>
												<label :for="item.ttid"></label>
											</div>
										</td>
										<td>A/S</td>
										<td>{{item.ttid}}</td>
										<td>{{item.curworkstepdesc}}</td>
										<td>{{item.svctypecodedesc}}</td>
										<td>{{item.troubreceiptdt}}</td>
										<td>{{item.svcid}}</td>
										<td class="asInfo-show" @click="asDetail(item.ttid)"><img src="@/assets/images/search_icon03.png" alt="" ></td>
										<td>{{item.cancelyn == 'Y' ? '예' : '아니오'}}</td>
									</tr>
								</tbody>
							</table>
							<div class="asInfo" v-show="ttlogDetail">
								<h4>A/S 상세정보</h4>
								<table class="tbl_type01">
									<tbody>
										<colgroup>
											<col style="">
											<col style="width: 300px;">
											<col style="width: 360px;">
											<col style="width: 300px;">
											<col style="width: 360px;">
										</colgroup>
										<tr>
											<th rowspan="4" class="bglightPurple2 bdr1px fcPurple2 agc">고객정보</th>
											<th>고객명</th>
											<td colspan="3">{{ttlogDetail.custname}}</td>
										</tr>
										<tr>
										<th>상품명</th>
											<td colspan="3">{{ttlogDetail.svctypecodedesc}}</td>
										</tr>
										<tr>
											<th>서비스번호</th>
											<td colspan="3">{{ttlogDetail.svcid}}</td>
										</tr>
										<tr>
											<th>연락처</th>
											<td colspan="3">{{ttlogDetail.reporterhpnum + '/' + ttlogDetail.reportertelnum }}</td>
										</tr>
										<tr>
											<th rowspan="4" class="bglightPurple2 bdr1px fcPurple2 agc bdt1px-purple">신청정보</th>
											<th class="bdt1px-purple">A/S 접수번호</th>
											<td class="bdt1px-purple">{{ttlogDetail.ttid}}</td>
											<th class="bdt1px-purple">신청채널</th>
											<td class="bdt1px-purple">{{ttlogDetail.troubsvctypecode}}</td>
										</tr>
										<tr>
											<th>신청일</th>
											<td>{{ttlogDetail.troubreceiptdt}}</td>
											<th>완료일</th>
											<td>{{ttlogDetail.curworkstepdt}}</td>
										</tr>
										<tr>
											<th>접수센터</th>
											<td>{{ttlogDetail.regcentercodedesc}}</td>
											<th>취소여부</th>
											<td>{{ttlogDetail.cancelyn == 'Y' ? '예' : '아니오'}}</td>
										</tr>
										<tr>
											<th>A/S 담당자</th>
											<td>{{ttlogDetail.delworkername == '' ? '-' : ttlogDetail.delworkername}}</td>
											<th>A/S 담당자 연락처</th>
											<td>{{ttlogDetail.delworkertelnum == '' ? '-' : ttlogDetail.delworkertelnum}}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<!-- AS신청현황 테이블 끝-->
							<!-- 상담신청현황 테이블 시작-->
							<table class="tbl_type02 mgt50 con02" v-show="this.typeList_sel == '04'">
								<colgroup>
									<col style="width: 85px;">
									<col style="width: 290px;">
									<col style="width: 235px;">
									<col style="">
									<col style="width: 85px;">
									<col style="width: 290px;">
									<col style="width: 290px;">
									<col style="width: 90px;">
								</colgroup>
								<thead>
									<tr>
										<th>구분</th>
										<th>서비스 유형</th>
										<th>고객/회사명</th>
										<th>문의유형</th>
										<th>문의내용</th>
										<th>등록일시</th>
										<th>발송일시</th>
										<th>발송여부</th>
									</tr>
								</thead>
								<tbody>
									<tr v-show="consultList == ''">
										<td colspan="8">조회된 데이터가 없습니다.</td>
									</tr>
									<tr v-for="item in consultList" :key="item.seq">
										<td>상담</td>
										<td>{{item.svctypecdnm}}</td>
										<td>{{item.custnm}}</td>
										<td>서비스 상품 문의</td>
										<td class="asInfo-show" @click="csDetail(item.custopnion)"><img src="@/assets/images/search_icon03.png" alt="" ></td>
										<td>{{item.regdt}}</td>
										<td>{{item.senddt}}</td>
										<td>{{item.sendyn == 'Y' ? '발송' : '미발송'}}</td>
									</tr>
								</tbody>
							</table>
							<!-- 상담신청현황 테이블 끝-->
							<!-- 변경예약 테이블 시작-->
							<table class="tbl_type02 mgt50 con02" v-show="this.typeList_sel == '05'">
								<colgroup>
									<col style="width: 50px;">
									<col style="width: 200px;">
									<col style="width: 100px;">
									<col style="width: 200px;">
									<col style="width: 200px;">
									<col style="width: 200px;">
									<col style="width: 200px;">
									<col style="">
								</colgroup>
								<thead>
									<tr>
										<th>선택</th>
										<th>SAID</th>
										<th>상태</th>
										<th>요청일시</th>
										<th>변경시작일</th>
										<th>변경종료일</th>
										<th>서비스</th>
										<th>변경속도</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="typeList_sel == '05' && result == ''">
										<td colspan="8">조회된 데이터가 없습니다.</td>
									</tr>
									<tr v-if="typeList_sel == '05' && result" v-for="item in result" :key="item.id">
										<td class="agc">
											<div class="checkbox tbl-ckRadio" v-if="item.upper_id == null && item.resvstatusnm === '예약'">
												<input type="radio" name="reservation" v-bind:id="item.id" v-bind:value="item.said" class="hide" @click="reservationCancel(item.id, item.said)">
												<label v-bind:for="item.id"></label>
											</div>
										</td>
										<td>{{item.said}}</td>
										<td>{{item.resvstatusnm}}</td>
										<td>{{item.regdt}}</td>
										<td>{{item.startresvdt}}</td>
										<td>{{item.endresvdt}}</td>
										<td>{{item.svctypenm}}</td>
										<td>다운로드 : {{item.linespecnm}} 업로드 : {{item.uploadspdnm}}</td>
									</tr>
								</tbody>
							</table>
							<!-- 변경예약 테이블 끝-->
							<h4 class="sLtitle" v-show="detailInfo" style="margin: 80px 0 40px 0; !important">상품 상세정보</h4>
							<table class="tbl_type01" v-show="detailInfo">
								<tbody>
									<colgroup>
										<col style="width: 300px;">
										<col style="">
									</colgroup>
									<tr>
										<th>가입상품</th>
										<td>{{detailInfo.svc_type_nm}}</td>
									</tr>
									<tr v-if="typeList_sel == '01'">
										<th>가입일자</th>
										<td>{{detailInfo.proc_dt}}</td>
									</tr>
									<tr v-if="(detailInfo.sub_sa_cd_nm != '' && detailInfo.sub_sa_cd_nm != null) || (detailInfo.add_ip_cnt > 0 && detailInfo.add_ip_cnt != null)">
										<th>부가서비스</th>
										<td>
											<span v-if="detailInfo.sub_sa_cd_nm != '' && detailInfo.sub_sa_cd_nm != null" style="margin-right: 10px;">{{ detailInfo.sub_sa_cd_nm }} ({{detailInfo.buga_spd_cd_nm}}) | </span>
											<span v-if="detailInfo.add_ip_cnt > 0 && detailInfo.add_ip_cnt != null">추가 IP {{detailInfo.add_ip_cnt}}개</span>
										</td>
									</tr>
									<tr v-if="detailInfo.addr != '' && detailInfo.addr != null">
										<th>설치주소</th>
										<td>{{detailInfo.addr}}</td>
									</tr>
									<tr v-if="typeList_sel == '01'">
										<th>납부방법</th>
										<td>{{detailAccountInfo.payMethCd}}</td>
									</tr>
									<tr v-if="typeList_sel == '01'">
										<th>결제수단</th>
										<td><p v-show="detailInfo">{{detailAccountInfo.payOrgNm}} / {{detailAccountInfo.payNo}}</p></td>
									</tr>
									<tr v-if="typeList_sel == '01'">
										<th>예금(카드)주</th>
										<td>{{detailAccountInfo.chagePayPrsnNm}}</td>
									</tr>
									<tr v-if="detailInfo.line_spec_nm != '' && detailInfo.line_spec_nm != null">
										<th>속도</th>
										<td>다운로드 {{detailInfo.line_spec_nm}} | 업로드 {{detailInfo.upload_spd_nm}}</td>
									</tr>
									<tr v-if="typeList_sel == '01'">
										<th>장비명</th>
										<td>{{detailInfo.equip_grp_nm}}</td>
									</tr>
									<tr v-if="typeList_sel == '01'">
										<th>개통/유지보수사</th>
										<td>{{detailInfo.maint_agent_nm}}</td>
									</tr>
									<tr v-if="typeList_sel == '01'">
										<th>스위치임대</th>
										<td>{{detailInfo.sub_equip_cd === null ? '미임대':'임대'}}</td>
									</tr>
									<tr v-if="this.typeList_sel == '02'">
										<th>인증구분</th>
										<td>{{ detailInfo.verify_type}}</td>
									</tr>
									<tr v-if="this.typeList_sel == '02'">
										<th>인증일자</th>
										<td>{{ detailInfo.call_date | date }}</td>
									</tr>
								</tbody>
							</table>
							<div class="btnBottomArea" v-show="this.typeList_sel == '01' || this.typeList_sel == '02'">
								<a @click="orderCancel()" class="btn-md01  fix btn-primary">취소</a>
							</div>
							<div class="btnBottomArea" v-show="this.typeList_sel == '03'">
								<a @click="asCancel()" class="btn-md01  fix btn-primary">취소</a>
							</div>
							<div class="btnBottomArea" v-show="this.typeList_sel == '05'">
								<a @click="reservationCancelBtn()" class="btn-md01  fix btn-primary">취소</a>
							</div>
							<div id="pop-info01" class="pop-wrap type02" v-bind:class="popupFlag ? 'show' : 'hide'">
								<div class="pop-inner pop-sm">
									<div class="pop-tit agc">
										문의내용
										<br />
										<p style="color:black; font-size: 16px; font-weight: 400; margin-top:15px;">
											{{consultDetail}}
										</p>
									</div>
									<a class="btn-sm btn-primary pop-close01" @click="popupFlag = false" >확인</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<section>
				<footerLayout></footerLayout>
			</section>
		</div>
	</body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'
import common from '@/assets/js/common'

export default {
	components: {
		HeaderLayout,
		FooterLayout
	},
	data () {
		return {
			popupFlag: false,
			typeList: '',
			typeList_sel: '01',
			scnList: '',
			scnList_sel: '',
			userInfo: '',
			userSvcInfo: '',
			custId_sel: '',
			aplshtId_sel: '',
			selectedTrtmStatCd: '',
			stateNm: '',
			result: '',
			ttlogList: '',
			ttlogDetail: '',
			consultList: '',
			consultDetail: '',
			as_sel: '',
			srlNo: '',
			// partyIdNumber: '',
			//상세정보 샘플데이터
			detailInfo: '',
			detailAccountInfo: '',
			reservedId: '',
			restoredId: '',
			prdcType: ''
		}
	},
	created: async function () {
	},
	mounted: async function () {
		//1. 고객ID불러오는 API호출
		await this.getComCodeList()
		await this.getOwnerContactInfoByTokenId()
	},
	methods: {
		init: function() {
			this.aplshtId_sel = ''
			this.detailInfo = ''
			this.detailAccountInfo = ''
			this.ttlogDetail = ''
		},
		selectValue: function(type, obj) {
			// console.log('OBJ : ', obj)
			if(type =='CUST'){
				// this.custId_sel = obj.custId
				this.custId_sel = obj.encCustId
			} else if (type == 'OD') {
				this.typeList_sel = obj.code_cd
				this.$refs.custnmid.innerHTML = `<span class="fcGy">고객명 / 고객ID</span>`
				this.$refs.scn.innerHTML = `<span class="fcGy">서비스계약ID</span>`
				this.scnList_sel = ''
				this.custId_sel = ''
				this.result = ''
				this.ttlogList = ''
				this.ttlogDetail = ''
				this.detailInfo = ''
			} else if(type == 'SCN') {
				this.scnList_sel = obj.scn
			} else if(type == 'AS') {
				this.as_sel = obj
			}
		},
		// 공통코드 조회
		async getComCodeList() {
			let payload = {
				group_cd : 'OD'
			}
			await this.$store.dispatch('gigaoffice/getComCodeList', payload).then(res => {
				this.typeList = res.data.response.results
			})
			.catch(err => {
				console.log(err)
			})
		},
		//1-1. 토큰으로 사업자 번호 조회
		getOwnerContactInfoByTokenId: async function() {
			let token_id = sessionStorage.getItem('accessToken')
			let param = {token_id: token_id}
			this.$store.dispatch("auth/getOwnerContactInfoByTokenId", param).then((res) => {
				this.ownerContactInfo = res
				setTimeout(() => {
					this.getUserInfo()//사업자번호
				}, 100);
				// this.getUserSvcInfo(this.ownerContactInfo.partyIdNumber1 ,this.ownerContactInfo.partyIdNumber2);// 법인번호
				this.getUserSvcInfo(this.ownerContactInfo.partyIdNumber1, this.ownerContactInfo.partyIdNumber2, this.ownerContactInfo.encPartyIdNumber1 ,this.ownerContactInfo.encPartyIdNumber2);
			})
			.catch(error => {
				console.error(error);
			});
		},
		// 고객 정보 조회
		getUserInfo: async function() {
			let param = {}
			await this.$store.dispatch("gigaoffice/getUserInfo", param).then((res) => {
				this.userInfo = res.data.result
			})
			.catch(error => { 
				console.log(error)
			})
		},
		// 계약 원부 조회 (custType 사업자번호:8/법인번호:3, custNum 사업자번호(10자리)/법인번호(13자리))
		// getUserSvcInfo: async function(custNum1 ,custNum2) {
		async getUserSvcInfo(custNum1, custNum2, encCustNum1, encCustNum2) {
			try {
				let custType = "" ,param = {} ,temp = [] ,key = 'scn';
				custNum1.length === 13 ? (custType = 3) : (custType = 8);
				param = {
					custType: custType,
					custNum: encCustNum1
				};
				const res1 = await this.$store.dispatch("gigaoffice/getUserSvcInfo", param);
				custNum2.length === 13 ? (custType = 3) : (custType = 8);
				// TODO TEST
				param = {
					custType: custType,
					custNum: encCustNum2
				};
				const res2 = await this.$store.dispatch("gigaoffice/getUserSvcInfo", param);

				if(res1 !== undefined && res1.length > 0){
					res1.forEach(function(value ,index){
						temp.push(value)
					})
				}
				if(res2 !== undefined && res2.length > 0){
					res2.forEach(function(value ,index){
						temp.push(value)
					})
				}
				temp = temp.reduce((i ,item) => {
					const removed = i.filter(j => j[key] !== item[key])
					return [...removed ,item]
				}, [])
				this.userSvcInfo = temp
			} catch (err) {
				console.log(err);
			}
		},
		//2. 신청내역 조회
		searchList: async function() {
			if(this.typeList_sel == '') {
				alert('구분을 선택해주세요.')
				return false
			}
			if(this.typeList_sel != '03' && this.custId_sel == '') {
				alert('고객명 / 고객ID를 선택해주세요.')
				return false
			}
			if(this.typeList_sel == '03' && this.scnList_sel == '') {
				alert('서비스ID를 선택해주세요.')
				return false
			}
			this.init()
			let param = {
				tokenId : sessionStorage.getItem('accessToken')
				, userId: sessionStorage.getItem('userId')
				, custInd: this.custId_sel
				, type: this.typeList_sel //codemst group_cd : 'OD'
			}
			//신청내역조회 api호출
			await this.$store.dispatch('gigaoffice/getOrderStatusList', param).then((res) => {
				if(this.typeList_sel == '01' || this.typeList_sel == '02'  || this.typeList_sel == '05') {
					this.result = res.data.response.list
					//AS신청내역
				} else if(this.typeList_sel == '03') {
					if(res.data.response.count > 0) {
						this.ttlogList = res.data.response.ttlogList
					} else {
						this.ttlogList = ''
					}
				} else if (this.typeList_sel == '04'){
					this.consultList = res.data.response.list
				}
			})
			.catch(error => {
				console.log(error)
			})
		},
		//3. 신청내역 클릭 시, 상품 상세정보 조회 기능
		searchDetailInfo: async function(e, item) {
			this.aplshtId_sel = item.aplshtid
			this.srlNo = item.srlno
			this.selectedTrtmStatCd = item.trtmstatcd
			let param = {'aplsht_id': item.encAplshtid}
			//api호출
			await this.$store.dispatch('gigaoffice/getSubscriptionListProductDetail', param).then((res) => {
				this.detailInfo = res.result
				setTimeout(() => {
					this.getAccountListInfo(this.detailInfo.encCustInd)
				}, 50);
			})
			.catch(error => {
				console.log(error)
			})
		},
		//3-1. 상세정보로 가져온 데이터로 청구정보 조회
		getAccountListInfo: async function(custInd) {
			let param = {custId : custInd}
			await this.$store.dispatch('gigaoffice/getAccountListInfo', param).then((res) => {
				let length = res.length
				let resArr = res
				//신청테이블과 billcust에서 bill_id = billAccId 인 것
				for(var i=0; i<length; i++) {
					if(resArr[i].billAccId === this.detailInfo.bill_id) {
						this.detailAccountInfo = res[i]
						this.prdcType = this.detailInfo.prdc_type
					}
				}
			})
			.catch(error => {
				console.log(error)
			})
		},
		asDetail: function (ttid) {
			this.ttlogDetail = this.ttlogList.find(item => item.ttid === ttid)
		},
		csDetail: function (custopnion) {
			this.consultDetail = custopnion
			this.popupFlag = true
		},
		//4. 신청 취소
		orderCancel: function() {
			//취소가능한상태인지 valid 체크
			if(this.aplshtId_sel === '') {
				alert('취소할 신청건을 선택해주세요.')
				return false
			}
			if(this.selectedTrtmStatCd != '1') {
				alert('접수중인 건만 취소가능합니다.')
				return false
			}
			if(confirm('신청을 취소하시겠습니까?')) {
				let param = {
					aplsht_id: this.aplshtId_sel
					, srlNo: this.srlNo
					// 법인명(고객명)
					, bizrCrprNm: this.userInfo.length == 0 ? '' : this.userInfo[0].corpNm
					// 대표자명
					, aplcntNm: this.userInfo.length == 0 ? '' : this.userInfo[0].reprNm == undefined ? '' : this.userInfo[0].reprNm
					, comEmail : sessionStorage.getItem('userEmail')
					, prdcType : this.prdcType
				}
				this.$store.dispatch('gigaoffice/cancelSubscription', param).then((res) => {
					// let result = JSON.stringify(res)
					if(res.status == 200) {
						alert('취소신청이 완료되었습니다.')
						window.location.reload()
					} else {
						alert('취소신청에 실패하였습니다.')
						window.location.reload()
					}
				})
				.catch(error => {
					alert('취소신청에 실패하였습니다.')
					console.log(error)
					window.location.reload()
				})
			}
		},
		asCancel: function () {
			if(this.as_sel.cancelyn == 'Y') {
				alert('이미 취소가 완료된 건입니다.')
				return false
			}
			if(this.as_sel.curworkstepdesc == '처리완료') {
				alert('이미 처리가 완료된 건입니다.')
				return false
			}
			if(confirm('신청을 취소하시겠습니까?')) {
				let param = {
					token_id : sessionStorage.getItem('accessToken'),
					ttid : this.as_sel.ttid,
					scn : this.as_sel.scn, // this.findTtid_scn(this.selectASListTtid),
					custName : this.as_sel.custname,
					custTelNum : this.as_sel.reporterhpnum == '' ? this.as_sel.reportertelnum : this.as_sel.reporterhpnum,//reporterhpnum, reportertelnum 
					troubSvcTypeCode : this.as_sel.troubsvctypecode//this.findTtid_troubsvctypecode(this.selectASListTtid)
				}
				this.$store.dispatch('gigaoffice/getGoSaOrderCancel', param).then((res) => {
					if(res.status == 200) {
						alert('취소신청이 완료되었습니다.')
						window.location.reload()
					} else {
						alert('취소신청에 실패하였습니다.')
						window.location.reload()
					}
				})
				.catch(error => {
					alert('취소신청에 실패하였습니다.')
					console.log(error)
					window.location.reload()
				})
			}
		},
		reservationCancel (id, said) {
			this.reservedId = id
			
			this.result.forEach(item => {
				if(item.said === said && item.upper_id == id){
					this.restoredId = item.id
				}
			})
		},
		reservationCancelBtn () {
			const formData = new FormData();
			formData.append('reservedId', this.reservedId)
			formData.append('restoredId', this.restoredId)

			if(confirm('신청을 취소하시겠습니까?')) {

				this.$store.dispatch('gigaoffice/postGoOrderSubscriptionReceiptReservationCancel', formData).then((res) => {
					if(res.data.code == '100' || res.data.code == '400') {
						alert('취소신청이 완료되었습니다.')
						window.location.reload()
					} else {
						alert('취소신청에 실패하였습니다.')
						window.location.reload()
					}
				})
				.catch(error => {
					alert('취소신청에 실패하였습니다.')
					console.log(error)
					window.location.reload()
				})
			}
		},
		//5. PDF 저장
		savePdf: function(aplshtId) {
			if(this.aplshtId_sel.length === 0) {
				alert('PDF로 저장할 신청건을 선택해주세요.')
				return false
			}
			if(this.selectedTrtmStatCd != '3') {
				alert('신청완료된 신청건만 PDF저장이 가능합니다.')
				return false
			}
			if(confirm('PDF파일로 저장하시겠습니까?')) {
				// window.open('/gigaoffice/goMyBiz103', '청약서', 'width=1144px')
				let url = '/gigaoffice/goMyBiz103'
				let title = '청약서'
				let w = 1200
				let h = 900
				common.openLoginPop(url, title, w, h)
			}
			// toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no, 
		},
	}
}
</script>
<style>
    .show { display:block !important; }
	.hide { display:none; }
</style>